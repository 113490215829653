import moment from "moment";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";

const tableColsPromocodes = [
  {
    text: "ID",
    value: "id",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    width: 25,
    sort: true
  },
  {
    text: "Статус",
    renderFunction: is_active => {
      if (is_active.status === "used") {
        let res = `<div style="color:#EB5C6D!important">Использован</div>`;
        return res;
      } else if (is_active.status === "expired") {
        let res = `<div style="color:#ff9b46!important">Просрочен</div>`;
        return res;
      } else if (is_active.status === "created") {
        let res = `<div style="color:#95C23D!important">Создан</div>`;
        return res;
      } else {
        let res = `<div style="color:#e3cc00!important">Деактивирован</div>`;
        return res;
      }
    },
    value: "status",
    sort: true
  },
  {
    text: "Код промокода",
    value: "code",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Скидка %",
    value: "discount_percent",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Город",
    value: "by_city",
    // eslint-disable-next-line no-unused-vars
    renderFunction: value => value.by_city?.name,
    sort: true
  },
  {
    text: "Тип доступа",
    value: "by_access_type",
    renderFunction: value => {
      return value.by_access_type === "app"
        ? "Приложение"
        : value.by_access_type === "card"
        ? "Карта"
        : "Ошибка";
    },
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },

  {
    text: "Тип объекта",
    value: "by_object_type",
    renderFunction: value =>
      dictionariesHelper.objectType[value.by_object_type],
    sort: true
  },
  {
    text: "Кем создан",
    renderFunction: value => value.created_by_detail?.full_name ?? "---",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    width: 25,
    sort: true
  },

  {
    text: "Дата начала активации",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.started_at * 1000).format("DD.MM.YYYY HH:mm");
    },
    value: "started_at",
    sort: true
  },
  {
    text: "Дата окончания активации",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.finished_at * 1000).format("DD.MM.YYYY HH:mm");
    },
    value: "finished_at",
    sort: true
  },
  {
    text: "Дата создания",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm");
    },
    value: "created_at",
    sort: true
  }
];

export default tableColsPromocodes;
